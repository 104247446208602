/**
 * 视频盒子绑定管理
 * @author tanguozheng
 * @date 2022-03-17 11:01
 */
<template>
    <div class="device-manage-page">
        <div class="filter-bar-box">
            <el-form  ref="form" :model="filter" inline>
                <el-form-item prop="equipCode" label="设备号" label-width="80px">
                    <el-input v-model="filter.equipCode" size="small" placeholder="请输入设备号" clearable></el-input>
                </el-form-item>
                <el-form-item prop="sim" label="视频盒子ID" label-width="120px">
                    <el-input v-model="filter.boxId" size="small" placeholder="请输入卡号" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="danger" @click="getLiveVBoxList" size="small">查 询</el-button>
                    <el-button type="primary" @click="handleAddRecord(1)" size="small">添加记录</el-button>
                    <el-button type="info" @click="downloadModule" size="small">下载模板</el-button>
                    <el-upload
                        style="display:inline-block;margin-left:10px"
                        ref="upload"
                        :headers="headers"
                        action="https://live.test.rootcloudapp.com//live-stream/backend/live-vbox/importBox"
                        :limit="1"
                        :show-file-list="false"
                        :auto-upload="true"
                        :before-upload="beforeUpload"
                        >
                            <el-button type="success" @click="getLiveVBoxList" size="small">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
        </div>
        <main class="main">
            <el-table :data="tableData" style="width: 100%" size="small">
                <el-table-column
                    type="index"
                    label="序号"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="equipCode"
                    label="设备号"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="equipName"
                    label="设备名"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="equipType"
                    label="设备类型"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="createTime"
                    label="添加时间"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="boxId"
                    label="视频盒子ID"
                    align="center"
                    >
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="操作"
                    align="center"
                    width="340"
                    >
                    <template slot-scope="scope">
                        <a style="color:#0400ff;margin-left:8px" @click="handleAddRecord(2,scope.row)">编辑</a>
                        <a style="color:#ff0000;margin-left:8px" @click="deleteVBox(scope.row)">删除</a>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination-box mt10">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 40, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
                </el-pagination>
            </div>
        </main>

        <el-dialog
            title="添加记录"
            :visible.sync="centerDialogVisible"
            width="30%"
            :rules="rules"
            center>
              <el-form :rules="rules" ref="addUserForm" :model="userDetails" label-width="120px" style="width:100%">
                <el-form-item prop="equipCode" label="设备号">
                      <el-input v-model="userDetails.equipCode" placeholder="请输入设备号"></el-input>
                </el-form-item>
                <el-form-item prop="boxId" label="视频盒子号">
                      <el-input v-model="userDetails.boxId" placeholder="请输入视频盒子号"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="addVBox" >确 认</el-button>
                <el-button @click="centerDialogVisible = false" >取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getLiveVBoxList, addVBox, updateVBox, deleteVBox, downloadModule, importBoxCheck, importBox } from '@/services'
import { getToken } from '@/utils/cookies'
export default {
    data() {
        return {
            centerDialogVisible: false,
            filter: {
                equipCode: '',
                boxId: '',
            },
            auditStatusMap: {
                '0': '待审核',
                '1': '已通过',
                '2': '未通过',
                '3': '已删除',
            },
            tableData: [],
            pageSize: 10,
            pageNo: 1,
            pageTotal: 0,
            userDetails: {
                boxId: '', //视频盒子号
                equipCode: '', // 设备编号
                id: ''
            },
            rules: {
                boxId: [
                    { required: true, message: '请输入视频盒子号', trigger: 'blur' },
                ],
                equipCode: [
                    { required: true, message: '请输入设备编号', trigger: 'blur' }
                ]
            },
            headers: {
                'Authorization': getToken(),
                'X-User-Id': sessionStorage.getItem('userId') || ''
            },
            fileList: [],
            autoUpload: true
        }
    },

    created() {
        this.getLiveVBoxList()
    },

    methods: {
        getLiveVBoxList() {
            getLiveVBoxList({
                ...this.filter,
                pageSize: this.pageSize,
                pageNo: this.pageNo
            }).then(res => {
                if(res.code == 200) {
                    let content = res.content || {}
                    this.tableData = content.records
                    this.pageTotal = content.total
                } else {
                    this.$message.error(res.message)
                }
            })
        },

        handleAddRecord(type, record) {
            this.centerDialogVisible = true
            if(type === 1) { // 添加
                this.userDetails = {
                    boxId: '', //视频盒子号
                    equipCode: '', // 设备编号
                    id: ''
                }
            } else {
                this.userDetails = {
                    boxId: record.boxId, //视频盒子号
                    equipCode: record.equipCode, // 设备编号
                    id: record.id, 
                }
            }
        },

        /**
         * 添加视频盒子
         * @author tanguozheng
         * @date 2022-05-17 17:31
         * @param {*}
         * @returns
         */
        addVBox() {
            if(this.userDetails.id !== '') {
                updateVBox(this.userDetails).then(res => {
                    if(res.code == 200) {
                        this.$message.success('修改成功')
                        this.centerDialogVisible = false
                        this.getLiveVBoxList()
                    } else {
                        this.$message.error(res.message)
                    }
                })
            } else {
                addVBox(this.userDetails).then(res => {
                    if(res.code == 200) {
                        this.$message.success('添加成功')
                        this.centerDialogVisible = false
                        this.getLiveVBoxList()
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        },

        deleteVBox(row) {
            deleteVBox({id: row.id}).then(res => {
                if(res.code == 200) {
                    this.getLiveVBoxList()
                    this.$message.success('删除成功')
                } else {
                    this.$message.error(res.message)
                }
            })
        },

        /**
         * 下载模板
         * @author tanguozheng
         * @date 2022-05-17 19:43
         */
        downloadModule() {
            downloadModule().then(res => {
                let fileName = '视频盒子导入模板.xlsx';
                let blob = new Blob([res], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});  
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
                window.URL.revokeObjectURL(link.href) //释放内存
            })
        },

          /**
         * 修改每页显示条数
         * @author tanguozheng
         * @date 2021-07-12 19:15
         * @param {*}
         * @returns
         */
        handleSizeChange(size) {
            this.pageNo = 1
            this.pageSize = size
            this.getLiveVBoxList()
        },

        /**
         * 翻页
         * @author tanguozheng
         * @date 2021-07-12 19:15
         * @param {*}
         * @returns
         */
        handleCurrentChange(currentPage) {
            this.pageNo = currentPage
            this.getLiveVBoxList()
        },

        beforeUpload(file) {
            const formData = new FormData()
            formData.append('file',file)
          
            importBoxCheck(formData).then(res => {
                if(res.code === 200) {
                    this.$confirm(res.message, '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                        }).then(() => {
                           importBox(formData).then(res => {
                               if(res.code == 200) {
                                    this.$message.success('导入成功')
                                    this.getLiveVBoxList()
                               }
                           })
                        }).catch(() => {
                         
                    });
                }
            })
            return false
        },
    }

}
</script>

<style lang="scss">
.device-manage-page{
    padding: 20px;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;

    .filter-bar-box{
        padding-top: 22px;

        background-color: #ffffff;
    }

    .main {
        background-color: #ffffff;
        margin-top: 22px;
        flex: 1;
        padding: 15px;
        .pagination-box{
            text-align: right;
        }
    }

}
.dialog-line-item {
    display: flex;
    margin-top: 20px;
    >div{
        width: 80px;
        text-align: right;
        margin-right: 20px;
    }

    img{
        width: 65%;
        height: 168px;
    }
}
</style>

